// The file contents for the current environment will overwrite these during build.
// The build system defaults to the ${env} environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json` hello.

export const API_URLS = {
    replenishment: 'https://replnapi-reimagination-uat.azurewebsites.net/api',
    env: 'https://accountapi-reimagination-uat.azurewebsites.net/api',
    configuration: 'https://configapi-reimagination-uat.azurewebsites.net/api',
    notificationHub: 'https://notificationapi-reimagination-uat.azurewebsites.net/',
    bot: 'https://directline.botframework.com/v3/directline',
    targetBaseUrl: 'https://localhost:44320/api',
    forecastURL:
      'https://eus-dev-play-as-forecastengine-api.azurewebsites.net/api',
    CLUSTER_API: 'https://eus-dev-play-oneview-clustering.azurewebsites.net',
    BBY_API: 'https://as-web-bbyapi-play-dev.azurewebsites.net/api',
    MSG_BASEURL: 'https://eus-dev-play-as-chatapi.azurewebsites.net',
    PMS_API: 'https://as-web-pms-api-play-dev.azurewebsites.net/api',
    QUICKPITCH_API:
      'https://eus-dev-play-as-quickpitch-api.azurewebsites.net/api',
    ClientId: 6,
  };
  export const environment = {
    version: '1.0.0',
    buildDate: '2024-09-25',
    TENANT_KEY: 'DefaultConnection',
    DATE_FORMAT: 'M/DD/YYYY',
    DEFAULT_MEASURE_TYPE: 'Units',
    HIDE_INTEGRATED_PLANNER: true,
    COMBO_PROFILE_ENABLE: false,
    HOMEPAGE_LOGO: '',
    ENABLE_HOMEBASE_LAYOUT_SETTING: true,
    SHOW_PLANNER_PERFORMANCE_BAR: true,
    WEEK_END_DAY: 5,
    DISABLE_WEEK_COUNT: 21,
    DISABLE_WEEK_MEASUREIDS: [444],
    IS_ITEM_LEVEL_PLANNING: false,
    HIDE_ASK_ME_BAR: true,
    ENABLE_TENANTS: false,
    ENABLE_SIGNALR: true,
    IS_TEAM_BASED_MENUS: true,
    ENABLE_JOURNEY: true,
    HIDE_GDP_RDP_TOGGLE: false,
    JOURNEY_MERGE:true,
    NAV_CONTENT_PANE_ENABLED: true,
    NOTIFICATIONS: true,
    HOME_PAGE_ENABLE: true,
    JOURNEY_BYOC: true,
    NOTES: true,
    CURRENCY: true,
    ENABLE_KPI: true,
    KPI_RECOMMEND_JOURNEYS: true,
    KPI_HOMEPAGE: true,
    ENABLE_GRAPHS: true,
    KPI_OUTCOME_COLUMN: true,
    ENABLE_KPI_NOTIFICATIONS: true,
    NLP_AUTO_COMPLETE: false,
    msalConfig: {
      clientId: 'f09914eb-4110-4905-928b-5f093d441698',
      authority:
        'https://login.microsoftonline.com/54c70f61-fcb4-416a-821d-2c1defe4860f',
      redirectUri: '/home',
      b2c:{
        enable: true,
        demoInvURL: 'https://inventorydev.algo.com/en-Au/#/redirect/DM',
        backendUrl: 'https://as-algo-shared-useridentity-service-dev.azurewebsites.net/api/token/validation',
        powerBiEmbedUrl: 'https://app.powerbi.com',
        clientId: "8d6663a5-5394-4ed5-ac25-5dee8ede5808",
        redirectUrl: "/en-Au",
        authorityDomain: "https://algoaib2c.b2clogin.com/algoaib2c.onmicrosoft.com",
        authority: "https://algoaib2c.b2clogin.com/algoaib2c.onmicrosoft.com/b2c_1_ms_useridentity_signin_signup",
        scope: "https://algoaib2c.onmicrosoft.com/msuseridentity-api/task.read",
        hostname: "http://localhost"
      }
    },
    okta: {
      enableOkta: false,
      addAuthorizationServer: true,
      OKTA_DOMAIN: `dev-30785815.okta.com`,
      CLIENT_ID: `0oa3zpnz4ejr7Etoi5d7`,
      CALLBACK_PATH: '/login/callback',
      AUTHORIZATION_SERVER: `/oauth2/default`,
      PROTOCOL: `https`,
      redirectUri: 'https://localhost:4200/login/callback',
      changePassword: 'https://dev-30785815.okta.com/enduser/settings',
      logoutUri: 'https://localhost:4200',
      showAlgoLogoutPage: false,
      isEnable: false,
    },
    turtleBeachLogin: {
      authority: 'https://algodev.onelogin.com/oidc/2',
      client_id: 'fb78b0c0-50c0-013d-7aac-6a920dd4614b240669',
      redirect_uri: 'http://localhost:4200/auth-callback',
      post_logout_redirect_uri: 'http://localhost:4200/',
      silent_redirect_uri: 'https://localhost:4200/silent-refresh.html',
      response_type: 'id_token token',
      scope: 'openid profile email',
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      isEnable: false,
    },
    agGridLicenseKey: `CompanyName=Algomus Inc,LicensedGroup=Algo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-037124,SupportServicesEnd=18_March_2024_[v2]_MTcxMDcyMDAwMDAwMA==31baa4eb45af976d60368922cbd068c8`,
    headerLogoURL: '',
    headerLogoEarth: '',
    headerLogoEarthHeight: 0,
    logoWidthEarth: 0,
    digitaWallet_buttonName: 'open',
    loginLogoURL: '',
    logoWidth: 70,
    logoWidthLogin: 200,
    logoWidthForget: 200,
    microsoftURL: `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`,
    ClientId: 6,
    production: true,
    idleTime: 8,
    fusionChartsKey:
      'qqA3ydjA5C5B3A5F5G4B3E3A10B5E2B2E2ixrG1C3gcowg1B3I4F-7mC-16B5B2D3D1G1F4H4B1C8A4D2G5G3C1G2B1ippB1A4B4i1A33A18B14utcB4A2H4moA-31A7C8A3D-11geH2C2C5A9B3B5F3G4F1C2F1zllC7E2f1G4i1dB-22C6D6B5jyD3RF1B5wwf1C2A3E2E3G3C9A6E4E4C4G3B2H2H4B-21==',
    AppSubscription: `${API_URLS.configuration}/Subscription`,
    AppUserPreference: `${API_URLS.configuration}/UserPreference`,
    AppTaskRequest: `${API_URLS.configuration}/TaskRequest`,
    AppTask: `${API_URLS.configuration}/Task`,
    AppJourney: `${API_URLS.configuration}/Journey`,
    AppJourneyLookUp: `${API_URLS.configuration}/JounreyLookUp`,
    AppJourneyCollaboration: `${API_URLS.configuration}/JourneyCollaboration`,
    AppJourneyContextLog: `${API_URLS.configuration}/JourneyContextLog`,
    AppJourneyContextLogComment: `${API_URLS.configuration}/JourneyContextLogComment`,
    AppJourneyInsightComments: `${API_URLS.configuration}/JourneyInsightComments`,
    AppJourneyUserActivityLog: `${API_URLS.configuration}/JourneyUserActivityLog`,
    AppPowerBi: `${API_URLS.configuration}/PowerBi`,
    AccessToken: `${API_URLS.configuration}/PowerBI/GetAccessToken`,
    BotSecretKey: `Y8noDdyqI2w.aFhA-ZQYDoFjdVZr-uX59wVyobs6ls1J3ukfeH_1-8w`,
    mapApi: `AIzaSyCH4mv9Pw3dRWz0yUoMyf4IexqPNUBdzvw`,
    utcOffset: 180,
    AppTokenGenerate: `${API_URLS.bot}/tokens/generate`,
    AppTokenRefresh: `${API_URLS.bot}/tokens/refresh`,
    AppConversations: `${API_URLS.bot}/conversations`,
    AppAccount: `${API_URLS.env}/Account`,
    AppClaim: `${API_URLS.env}/Claim`,
    AppClaimGroup: `${API_URLS.env}/ClaimGroup`,
    AppRole: `${API_URLS.env}/Role`,
    AppCalender: `${API_URLS.configuration}/Calender`,
    AppCategory: `${API_URLS.configuration}/Category`,
    AppChannel: `${API_URLS.configuration}/Channel`,
    AppClient: `${API_URLS.configuration}/Client`,
    AppDistributor: `${API_URLS.configuration}/Distributor`,
    AppForms: `${API_URLS.configuration}/Forms`,
    AppSource: `${API_URLS.configuration}/Sourcing`,
    AppGoal: `${API_URLS.configuration}/Goal`,
    AppGoalType: `${API_URLS.configuration}/GoalTypes`,
    AppGoalTenure: `${API_URLS.configuration}/GoalTenureTypes`,
    AppSourceMixForm: `${API_URLS.configuration}/SourceMixForm`,
    AppSourceMixRow: `${API_URLS.configuration}/SourceMixRow`,
    AppSourceMixHeader: `${API_URLS.configuration}/SourceMixHeader`,
    AppCategoryMixRow: `${API_URLS.configuration}/CategoryMixRow`,
    AppCategoryMixHeader: `${API_URLS.configuration}/CategoryMixHeader`,
    AppCategoryMixForm: `${API_URLS.configuration}/CategoryMixForm`,
    AppStoreMixRow: `${API_URLS.configuration}/NewFormRow`,
    AppStoreMixHeader: `${API_URLS.configuration}/NewFormHeader`,
    AppStoreMixForm: `${API_URLS.configuration}/NewForm`,
    AppSameStoreRow: `${API_URLS.configuration}/SameStoreRow`,
    AppSameStoreHeader: `${API_URLS.configuration}/SameStoreHeader`,
    AppSameStoreForm: `${API_URLS.configuration}/SameStoreForm`,
    AppCalenderActivities: `${API_URLS.configuration}/CalendarActivity`,
    AppEventTypes: `${API_URLS.configuration}/EventTypes`,
    AppActionItems: `${API_URLS.configuration}/ActionItems`,
    AppChannelSourceWise: `${API_URLS.configuration}/ChannelSourceWise`,
    AppMenu: `${API_URLS.configuration}/Menu`,
    AppAnnouncement: `${API_URLS.configuration}/Announcement`,
    AppNote: `${API_URLS.configuration}/Note`,
    AppAPBudgetType: ``,
    AppPromotion: ``,
    AppSKU: ``,
    AppSKUPromotion: `Promotion`,
    AppDecayCurve: ``,
    AppItemMaster: ``,
    AppBussinessAttribute: ``,
    AppBussinessInformation: ``,
    AppWorkgroup: ``,
    AppLevel: ``,
    AppUserLevel: ``,
    UploadDoc: ``,
    //UploadDoc: `https://localhost:44399/api/AnomalyDetector/Upload`,
    Anomoly: ``,
    AnomolyFiles: ``,
    SyncPowerBIReports: `${API_URLS.configuration}/PowerBI/GetReportList`,
    NotificationCommon: `${API_URLS.configuration}/notification`,
    NotificationHub: `${API_URLS.notificationHub}/notifications`,
    ApplicationInsights: `${API_URLS.configuration}/ApplicationInsights`,
    AppDocument: ``,
    AppCommonCode: `${API_URLS.configuration}/CommonCode`,
    AppSuggestAdmin: ``,
    AppCurrency: `${API_URLS.configuration}/CurrencySetting`,
    AppTeams: `${API_URLS.env}/Team`,
    AppFBSpreading: `${API_URLS.configuration}/Spreading`,
    AppCurrencyRate: `${API_URLS.configuration}/CurrencyRate`,
    AppBuyerSetting: `${API_URLS.configuration}/BuyerSetting`,
    AppFinanceSetting: `${API_URLS.configuration}/FinancialSetting`,
    AppProductSetting: `${API_URLS.configuration}/ProductSetting`,
    AppFBForcast: `${API_URLS.configuration}/FBForcast`,
    AppItemManager: `${API_URLS.configuration}/ItemManager`,
    AppTeamScreenGroup: `${API_URLS.configuration}/TeamScreenGroup`,
    AppItemForcast: `${API_URLS.configuration}/ItemForecast`,
    AppAccountSettingMapping: `${API_URLS.configuration}/AccountSettingMapping`,
    AppProductSettingMapping: `${API_URLS.configuration}/ProductSettingMapping`,
    FbForecastProfile: `${API_URLS.configuration}/FBMeasureProfile`,
    MsForecastProfile: `${API_URLS.configuration}/MSMeasureProfile`,
    PromotionPlanner: `${API_URLS.configuration}/PromotionPlanner`,
    OrderList: `${API_URLS.configuration}/MSOrderList`,
    AppBulkCommit: `${API_URLS.configuration}/BulkCommitJob`,
    maxPlannerSaveCount: 20,
    Ess: '',
    autoCompleteApi: 'https://autocomplete-api-demo.azurewebsites.net',
    GenAiapi: `https://questionanswer-gen-ai-api-demo.azurewebsites.net`,
    SkylightForecast: `${API_URLS.targetBaseUrl}/Forecast`,
    TargetBaseUrl: `${API_URLS.targetBaseUrl}`,
    outlierManager: `${API_URLS.forecastURL}/OutlierManager`,
    MappingManager: `${API_URLS.forecastURL}/MappingManager`,
    PromoManager: `${API_URLS.forecastURL}/PromoManager`,
    SeasonalityManager: `${API_URLS.forecastURL}/SeasonalityManager`,
    ForecastManager: `${API_URLS.forecastURL}/ForecastManager`,
    ForecastMapping: `${API_URLS.forecastURL}/Lookup`,
    KPIList: `${API_URLS.configuration}/KPI`,
    CoreArea: `${API_URLS.configuration}/CoreArea`,
    KPISubscription: `${API_URLS.configuration}/KPISubscription`,
    KPISlicer: `${API_URLS.configuration}/KPISlicer`,
    GlobalEntity: `${API_URLS.configuration}/Global`,
    Homebase: `${API_URLS.configuration}/HomeBase`,
    POG: `${API_URLS.configuration}/POG`,
    MasterPromo: `${API_URLS.configuration}/MasterPromo`,
    WebPush: `${API_URLS.configuration}/WebPush`,
    VapidPublicKey:
      'BPfqBgB2oNNQn0Wywm9df6QiLz5fJold15DynhsYPZJIB2LHoPmp7X9G4LQzMUOhLal8z5ZJ1C5rUzwl0_p9Pyo',
    LinkedItemMaster: `${API_URLS.targetBaseUrl}/LinkedItemMaster`,
    WebPushNotification: true,
    KPITile: `${API_URLS.configuration}/KPITile`,
    KPITrack: `${API_URLS.configuration}/KPITrack`,
    KPIError: `${API_URLS.configuration}/KPIErrorInfo`,
    CronSchedule: `${API_URLS.configuration}/CronSchedule`,
    AccountHierarchy: `${API_URLS.configuration}/AccountHierarchy`,
    ItemHierarchy: `${API_URLS.configuration}/ItemHierarchy`,
    CalendarHierarchy: `${API_URLS.configuration}/CalendarHierarchy`,
    ETL: `${API_URLS.configuration}/ETL`,
    AppTenant: `${API_URLS.env}/Tenant`,
    AppGetClientAlias: `${API_URLS.configuration}/ClientAlias`,
    // Data Clustering
    ClusterAPI: `${API_URLS.CLUSTER_API}/cluster-api`,
    ClusterBaseUrl: `${API_URLS.CLUSTER_API}`,
    FileManagementURL: `${API_URLS.CLUSTER_API}/file-management-api`,
    chuckUploadConfig: {
      noOfChunks: 20,
      minFileChuckSize: 1024 * 1024 * 10, //10MB
      blockSize: {
        size512KB: 1024 * 512,
        size4Mb: 1024 * 1024 * 4,
        size32Mb: 1024 * 1024 * 32,
        size10MB: 1024 * 1024 * 10,
      },
    },
    AppReplenishmentUserProfile: `${API_URLS.replenishment}/ReplenishmentUserProfile`,
    AppException: `${API_URLS.replenishment}/ReplenishmentException`,
    ReplenishmentConfig: `${API_URLS.replenishment}/ReplenishmentConfig`,
    ReplenishmentPOG: `${API_URLS.replenishment}/ReplenishmentPOG`,
    ReplenishmentReview: `${API_URLS.replenishment}/ReplenishmentReview`,
    AppBackgroundJob: `${API_URLS.configuration}/BackgroundJob`,
    Scheduler: `${API_URLS.configuration}/Scheduler`,
    AppClientConfigurations:  `${API_URLS.configuration}/ClientConfigurations`,
    DataPERM: `${API_URLS.configuration}/dataPERM`,
    RunningWeekAccSetting: `${API_URLS.configuration}/RunningWeekAccSetting`
  };

